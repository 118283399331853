body {
  overscroll-behavior: none;
}

.rs-map {
  position: relative;
  height: 100%;
  width: 100%;
}

.rt-search {
  position: absolute;
  top: 0;
  left: 17%;
  margin: 10px;
  display: flex;
  flexdirection: column;
  width: 64%;
}

.rs-geolocation {
  top: 10px;
  right: 10px;
  position: absolute;
}

.rs-geolocation.rs-active {
  background-color: blue;
  -webkit-animation-name: none;
  animation-name: none;
}

.rs-zooms-bar {
  top: 70px;
  right: 10px;
  position: absolute;
}

.rs-zoom-in,
.rs-zoom-out {
  background: blue;
  border-radius: 50%;
  color: white;
}

.rs-w-xs .rs-popup {
  left: 0 !important;
  right: 0;
  bottom: 0;
  top: auto !important;
  border: 1px solid #e8e8e8;
}

.rs-w-xs .rs-popup > div {
  position: relative;
  left: 0;
  bottom: 0;
  min-width: 0;

  &::after,
  &::before {
    display: none;
  }
}

.rs-popup-body ul {
  list-style-type: none;
  padding: 0;
}

.rs-layer-tree {
  /*     background: white; */
  /*     padding: 10px 5px; */
  max-width: 120px;
  min-width: 50px;
  width: 15%;
  /*     height: 450px; */

  top: 10px;
  left: 10px;
  bottom: 45px;
  position: absolute;
  margin: auto;

  display: flex;
  flex-direction: column;
  margin: 0;
}
.rs-layer-tree .rs-layer-tree-item {
  padding: 0;
}

.rs-layer-tree .rs-layer-tree-item > * {
  margin-right: 0;
}
.rs-layer-tree img {
  width: 100%;
  max-height: 100px;
  max-width: 100px;
  border-radius: 7px;
  display: block;
}

.grayOut {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
}
.rs-copyright {
  position: 10;
  bottom: 0px;
  right: 10px;
  /* height: 100px; */
  /* width: 100px; */
  position: absolute;
  background: white;
}

.rs-info {
  top: 430px;
  right: 10px;
  position: absolute;
  cursor: pointer;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  color: white;
  background-color: #eb0000;
  transition: background-color 0.5s ease;
}
